<template>
  <div class="cursor-pointer" v-if="tag" @click="collapse = !collapse">
    <div style="display: flex; justify-content: center; align-items: center">
      <span class="position-relative">{{ tag.name }}</span>
      <div class="mt-1 text-xs" style="color: white">
        <v-icon :class="{ hide: !collapse }" style="color: white"
          >mdi-chevron-down</v-icon
        >
        <v-icon :class="{ hide: collapse }" style="color: white"
          >mdi-chevron-up</v-icon
        >
      </div>
    </div>

    <div
      class="text-left text-black bg-white p-2 rounded"
      :class="{ hide: collapse }"
    >
      <div>
        <span>Criteria</span> <span class="text-blue">{{ tag.criteria }}</span>
      </div>
      <div v-if="tag.explanation">
        <span>Explain:</span>
        <span class="text-red">
          {{ tag.explanation }}
        </span>
      </div>
      <!--      <div v-if="tag.solution">
        <span>Solution:</span><span class="text-green">
                       {{ tag.solution }}
                     </span>
      </div>-->
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorTag",
  props: {
    tag: {},
  },
  data: function () {
    return {
      collapse: true,
    };
  },
};
</script>
<style scoped>
.hide {
  display: none;
}

.text-black {
  color: black;
}

.text-red {
  color: red;
}

.text-blue {
  color: blue;
}

.text-green {
  color: green;
}
</style>
